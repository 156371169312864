import './app.styles.scss';

jQuery(document).ready(function ($) {
  $('#menu-item-73291 a').on('click', function (e) {
    e.preventDefault();
    $('#signModal').modal('show');
  });

  $('.buttonModal').click(function () {
    const modalId = $(this).attr('data-modal-id');
    $('#modal-' + modalId).modal('show');
  });

  $(document.body).on('touchmove', onScroll);

  function onScroll(e) {
    let el = $('#masthead');

    if (typeof jQuery === 'function' && el instanceof jQuery) {
      el = el[0];
    }

    const rect = el.getBoundingClientRect();

    if (rect.top < -60) {
      $('.vc_row.course-nav.sticky-element').css('position', 'fixed');
      $('.vc_row.course-nav.sticky-element').css('width', '100%');
    } else {
      $('.vc_row.course-nav.sticky-element').css('position', 'relative');
    }
  }

  const inputID = 'yikes-easy-mc-form-4-QUESTION';

  jQuery('#' + inputID).each(function () {
    const style = jQuery(this).attr('style');
    const name = jQuery(this).attr('name');
    const id = jQuery(this).attr('name');
    const className = jQuery(this).attr('class');

    const textbox = jQuery(document.createElement('textarea'))
      .attr('style', style)
      .attr('name', name)
      .attr('id', id)
      .attr('class', className);

    jQuery(this).replaceWith(textbox);
  });

  jQuery('.yikes-easy-mc-error-message').each(function () {
    const text = jQuery(this).text();

    if (text === 'Please check the reCAPTCHA field.') {
      jQuery(this).text('Pole reCAPTCHA jest wymagane');
    }

    if (text === 'A required interest group is missing.') {
      jQuery(this).text('Pole "Rodzaje wiadomości, które chcesz otrzymywać" jest wymagane');
    }
  });

  const removeEmptyTagContainer = jQuery('.post-tag-share-container');

  if (removeEmptyTagContainer) {
    removeEmptyTagContainer.parent().parent().remove();
  }
});
